<template>
  <div>
    <div class="box" id="canopyManagement">
      <div class="b_div" v-for="(item,index) in detailList" :key="index">
        <div class="b_div_son">
          <div class="haad-title">{{ item.name }}</div>
          <el-row v-if="item.imgUrl">
            <img :src="item.imgUrl" alt="" width="290" height="180">
          </el-row>
          <el-row v-else>
            <img src="../../assets/noVideo.jpg" alt="" width="290" height="180">
          </el-row>
          <div  style="margin-top: 20px" v-if="item.url">
            <el-button @click="clickVideo(item.url,item)">管理</el-button>
          </div>
          <div  style="margin-top: 20px" v-else>
            <el-button disabled>管理</el-button>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
        :title="videoTitle"
        :visible.sync="dialogVisible"
        width="700px"
        style="text-align: center"
        v-if="dialogVisible"
    >
<!--      <iframe-->
<!--          src="ezopen://open.ys7.com/F96490074/1.hd.live"-->
<!--          width="600"-->
<!--          height="400"-->
<!--          id="ysOpenDevice"-->
<!--          allowfullscreen-->
<!--      >-->
<!--      </iframe>-->
      <e-z-u-i-kit-js :url="url" :accessToken="accessToken"></e-z-u-i-kit-js>
      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
<!--    <el-button type="primary" @click="dialogVisible = false">确 定</el-button>-->
  </span>
    </el-dialog>
  </div>
</template>

<script>
import EZUIKitJs from '@/components/EZUIKitJs.vue'
export default {

  data() {
    return {
      dialogVisible: false,
      url: '123',
      accessToken:'',
      detailList:[],
      pageIndex: 1,
      pageSize: 8,
      totalPage: 0,
      videoTitle:'视频播放'
    }
  },
  components:{
    EZUIKitJs
  },
  mounted() {
    this.bId = this.$route.query.id || localStorage.getItem('detailId')
    console.log(this.bId,'bId')
    let params = {
      page:this.pageIndex,
      size:this.pageSize
    }
    this.$api.post('videos/getList', params, res => {
          console.log(res)
          if (res.data){
            this.detailList = res.data.records
          }

        })
  },
  methods: {
    toDeatil() {
    },
    clickVideo(url,data){
      console.log(data,'-=-=')
      console.log(url)
      this.dialogVisible = true
      this.url = url
      this.accessToken = data.accessToken
      this.videoTitle = '视频播放[ '+data.name +' ]'


    }

  }
}
</script>

<style>

.box {
  width: 1200px;
  height: 1000px;
  margin: 20px auto;
  /*border: 1px solid #ccc;*/
}

.b_div {
  width: 290px;
  height: 320px;
  padding: 5px;
  float: left;
}

.b_div_son {
  width: 290px;
  height: 320px;
  /*border: 1px solid #333;*/
  background: #FFFFFF;
  text-align: center;
}

.haad-title {
  height: 59px;
  line-height: 59px;
  font-size: 20px;
  color: #009400;
  border-bottom: 1px solid #E8E8E8;
}

.center-box-img {
  line-height: 68px;
  text-align: center
}

.center-box-size-title {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;

}

.center-box-size-con {
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #0090FF;

}

#canopyManagement .el-button {
  width: 250px;
  height: 40px;
  background: #009201;
  border-radius: 20px;
  color: #FFFFFF;
  margin-top: 10px;
}
</style>
